import React from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { useNavigation, useRoute, RouteProp } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import Snackbar from '~/components/Snackbar';
import { NO_PAG_LIST_OFFSET } from '~/data/constants';
import {
  ProfilePagePhotoCategory,
  ProfilePageQuestion,
} from '~/data/models/yearbook';
import {
  CREATE_PROFILE_PAGE_PHOTO_CATEGORY,
  CREATE_PROFILE_PAGE_QUESTION,
  DESTROY_PROFILE_PAGE_PHOTO_CATEGORY,
  DESTROY_PROFILE_PAGE_QUESTION,
  MODULE_INSTANCE,
  MOVE_PROFILE_PAGE_QUESTION_TO_POSITION,
  PROFILE_PAGE_PHOTO_CATEGORIES,
  PROFILE_PAGE_QUESTIONS,
  UPDATE_MODULE_INSTANCE,
  UPDATE_PROFILE_PAGE_PHOTO_CATEGORY,
  UPDATE_PROFILE_PAGE_QUESTION,
  UPDATE_PROFILE_PAGE_SETUP,
} from '~/data/operations/yearbook';
import {
  readProfilePagePhotoCategoriesQuery,
  readProfilePageQuestionsQuery,
  writeProfilePagePhotoCategoriesQuery,
  writeProfilePageQuestionsQuery,
} from '~/data/operations/yearbook/helpers';
import { YearbookStackParamList } from '~/navigation/types';
import { t } from '~/utils/i18n';
import { ModuleSetupLayoutLoading } from '../layout/ModuleSetupLayout';
import ProfilePageSetupLayout, {
  UpdateModuleInstanceInput,
  UpdateProfilePageSetupInput,
  ProfilePageQuestionMap,
} from './layout';

type ProfilePageSetupNavProp = StackNavigationProp<
  YearbookStackParamList,
  'ProfilePageSetup'
>;
type ProfilePageSetupRouteProp = RouteProp<
  YearbookStackParamList,
  'ProfilePageSetup'
>;

export default function ProfilePageSetup(): JSX.Element {
  const navigation = useNavigation<ProfilePageSetupNavProp>();
  const {
    params: { moduleInstanceId },
  } = useRoute<ProfilePageSetupRouteProp>();

  const { data: moduleInstanceData } = useQuery(MODULE_INSTANCE, {
    variables: {
      id: moduleInstanceId,
    },
  });

  const profilePagePhotoCategoriesVar = {
    moduleInstance: moduleInstanceId,
    first: NO_PAG_LIST_OFFSET,
  };
  const { data: photoCategoriesData, loading: photoCategoriesLoading } =
    useQuery(PROFILE_PAGE_PHOTO_CATEGORIES, {
      variables: {
        moduleInstance: moduleInstanceId,
        first: NO_PAG_LIST_OFFSET,
      },
    });

  const photoCategories: ProfilePagePhotoCategory[] =
    (photoCategoriesData?.profilePagePhotoCategories?.edges.map(
      (edge) => edge?.node,
    ) as ProfilePagePhotoCategory[]) || [];

  const moduleInstance = moduleInstanceData?.moduleInstance;

  const profilePageSetupConfig =
    moduleInstanceData?.moduleInstance?.profilePageSetup;

  const profilePageQuestionsVar = {
    moduleInstance: moduleInstanceId,
    first: NO_PAG_LIST_OFFSET,
  };
  const { data: questionsData } = useQuery(PROFILE_PAGE_QUESTIONS, {
    variables: profilePageQuestionsVar,
  });

  const [createProfilePagePhotoCategory] = useMutation(
    CREATE_PROFILE_PAGE_PHOTO_CATEGORY,
  );
  const [updateProfilePagePhotoCategory] = useMutation(
    UPDATE_PROFILE_PAGE_PHOTO_CATEGORY,
  );
  const [destroyProfilePagePhotoCategory] = useMutation(
    DESTROY_PROFILE_PAGE_PHOTO_CATEGORY,
  );

  const [updateModuleInstance] = useMutation(UPDATE_MODULE_INSTANCE);
  const [updateProfilePageSetup] = useMutation(UPDATE_PROFILE_PAGE_SETUP);

  const [createProfilePageQuestion] = useMutation(CREATE_PROFILE_PAGE_QUESTION);
  const [updateProfilePageQuestion] = useMutation(UPDATE_PROFILE_PAGE_QUESTION);
  const [destroyProfilePageQuestion] = useMutation(
    DESTROY_PROFILE_PAGE_QUESTION,
  );

  const [moveProfilePageQuestionToPosition] = useMutation(
    MOVE_PROFILE_PAGE_QUESTION_TO_POSITION,
  );

  const questions: ProfilePageQuestion[] =
    (questionsData?.profilePageQuestions?.edges.map(
      (edge) => edge?.node,
    ) as ProfilePageQuestion[]) || [];

  const onUpdateModuleInstance = async ({
    isActive,
    dueDate,
  }: UpdateModuleInstanceInput) => {
    try {
      await updateModuleInstance({
        variables: {
          input: {
            id: moduleInstanceId,
            isActive,
            dueDate,
          },
        },
      });
    } catch (e) {
      if (e instanceof Error) {
        Snackbar.show(e.message);
      }
    }
  };

  const onUpdateProfilePageSetup = async ({
    photoFormat,
    maxChars,
    anonymousComments,
    visibleComments,
  }: UpdateProfilePageSetupInput) => {
    try {
      if (moduleInstance && profilePageSetupConfig) {
        await updateProfilePageSetup({
          variables: {
            input: {
              id: profilePageSetupConfig.id,
              isActive: moduleInstance.isActive,
              dueDate: moduleInstance.dueDate,
              photoFormat,
              maxChars,
              anonymousComments,
              visibleComments,
            },
          },
        });
      }
    } catch (e) {
      if (e instanceof Error) {
        Snackbar.show(e.message);
      }
    }
  };

  const onAddedPhotoCategory = async (
    name: string,
    photoCategoryId?: string,
  ) => {
    try {
      if (photoCategoryId) {
        await updateProfilePagePhotoCategory({
          variables: {
            input: {
              id: photoCategoryId,
              name,
            },
          },
          update(cache, { data }) {
            const updatedProfilePagePhotoCategory =
              data?.updateProfilePagePhotoCategory?.profilePagePhotoCategory;

            const currentProfilePagePhotoCategories =
              readProfilePagePhotoCategoriesQuery({
                cache,
                variables: profilePagePhotoCategoriesVar,
              });

            if (
              currentProfilePagePhotoCategories?.profilePagePhotoCategories
                ?.edges &&
              updatedProfilePagePhotoCategory
            ) {
              writeProfilePagePhotoCategoriesQuery({
                cache,
                variables: profilePagePhotoCategoriesVar,
                data: {
                  ...currentProfilePagePhotoCategories,
                  profilePagePhotoCategories: {
                    ...currentProfilePagePhotoCategories.profilePagePhotoCategories,
                    edges:
                      currentProfilePagePhotoCategories?.profilePagePhotoCategories?.edges.map(
                        (edge) => {
                          if (edge?.node?.id === photoCategoryId) {
                            return {
                              ...edge,
                              node: {
                                ...updatedProfilePagePhotoCategory,
                                profilePagePhotos:
                                  edge.node.profilePagePhotos || null,
                              },
                            };
                          }
                          return edge;
                        },
                      ),
                  },
                },
              });
            }
          },
        });
      } else {
        await createProfilePagePhotoCategory({
          variables: {
            input: {
              moduleInstance: moduleInstanceId,
              name,
            },
          },
          update(cache, { data }) {
            const newProfilePagePhotoCategory =
              data?.createProfilePagePhotoCategory?.profilePagePhotoCategory;

            const currentProfilePagePhotoCategories =
              readProfilePagePhotoCategoriesQuery({
                cache,
                variables: profilePagePhotoCategoriesVar,
              });

            if (
              currentProfilePagePhotoCategories?.profilePagePhotoCategories
                ?.edges &&
              newProfilePagePhotoCategory
            ) {
              writeProfilePagePhotoCategoriesQuery({
                cache,
                variables: profilePagePhotoCategoriesVar,
                data: {
                  ...currentProfilePagePhotoCategories,
                  profilePagePhotoCategories: {
                    ...currentProfilePagePhotoCategories.profilePagePhotoCategories,
                    edges: [
                      ...currentProfilePagePhotoCategories
                        .profilePagePhotoCategories.edges,
                      {
                        __typename: 'ProfilePagePhotoCategoryNodeEdge',
                        node: {
                          ...newProfilePagePhotoCategory,
                          profilePagePhotos: null,
                        },
                      },
                    ],
                  },
                },
              });
            }
          },
        });
      }
    } catch (e) {
      if (e instanceof Error) {
        Snackbar.show(e.message);
      }
    }
  };

  const onAddedQuestion = async (text: string, id?: string) => {
    try {
      if (id) {
        await updateProfilePageQuestion({
          variables: {
            input: {
              id,
              text,
            },
          },
          update(cache, { data }) {
            const updatedProfilePageQuestion =
              data?.updateProfilePageQuestion?.profilePageQuestion;

            const currentProfilePageQuestions = readProfilePageQuestionsQuery({
              cache,
              variables: profilePageQuestionsVar,
            });

            if (
              currentProfilePageQuestions?.profilePageQuestions?.edges &&
              updatedProfilePageQuestion
            ) {
              writeProfilePageQuestionsQuery({
                cache,
                variables: profilePageQuestionsVar,
                data: {
                  ...currentProfilePageQuestions,
                  profilePageQuestions: {
                    ...currentProfilePageQuestions.profilePageQuestions,
                    edges:
                      currentProfilePageQuestions?.profilePageQuestions?.edges.map(
                        (edge) => {
                          if (edge?.node?.id === id) {
                            return {
                              ...edge,
                              node: {
                                ...updatedProfilePageQuestion,
                                profilePageAnswers:
                                  edge.node.profilePageAnswers || null,
                              },
                            };
                          }
                          return edge;
                        },
                      ),
                  },
                },
              });
            }
          },
        });
      } else {
        await createProfilePageQuestion({
          variables: {
            input: {
              moduleInstance: moduleInstanceId,
              text,
            },
          },
          update(cache, { data }) {
            const newProfilePageQuestion =
              data?.createProfilePageQuestion?.profilePageQuestion;

            const currentProfilePageQuestions = readProfilePageQuestionsQuery({
              cache,
              variables: profilePageQuestionsVar,
            });

            if (
              currentProfilePageQuestions?.profilePageQuestions?.edges &&
              newProfilePageQuestion
            ) {
              writeProfilePageQuestionsQuery({
                cache,
                variables: profilePageQuestionsVar,
                data: {
                  ...currentProfilePageQuestions,
                  profilePageQuestions: {
                    ...currentProfilePageQuestions.profilePageQuestions,
                    edges: [
                      ...currentProfilePageQuestions.profilePageQuestions.edges,
                      {
                        __typename: 'ProfilePageQuestionNodeEdge',
                        node: {
                          ...newProfilePageQuestion,
                          profilePageAnswers: null,
                        },
                      },
                    ],
                  },
                },
              });
            }
          },
        });
      }
    } catch (e) {
      if (e instanceof Error) {
        Snackbar.show(e.message);
      }
    }
  };

  const onAddQuestion = () => {
    navigation.navigate('ProfilePageAddQuestion', {
      onAddedQuestion: (text) => onAddedQuestion(text),
    });
  };

  const onUpdateQuestion = (id: string, currentText: string) => {
    navigation.navigate('ProfilePageAddQuestion', {
      currentText,
      onAddedQuestion: (text) => onAddedQuestion(text, id),
    });
  };

  const onUpdatePhotoCategory = (
    photoCategoryId: string,
    currentText: string,
  ) => {
    navigation.navigate('ProfilePageAddPhotoCategory', {
      currentText,
      onAddedPhotoCategory: (text) =>
        onAddedPhotoCategory(text, photoCategoryId),
    });
  };

  const onUpdateQuestionsOrder = async (
    questionId: string,
    newPosition: number,
    updatedQuestionOrderIds: string[],
  ) => {
    try {
      await moveProfilePageQuestionToPosition({
        variables: {
          input: {
            id: questionId,
            newPosition,
          },
        },
        update(cache) {
          const currentProfilePageQuestionQuery = readProfilePageQuestionsQuery(
            {
              cache,
              variables: profilePageQuestionsVar,
            },
          );

          if (currentProfilePageQuestionQuery?.profilePageQuestions?.edges) {
            const currentProfilePageQuestions: ProfilePageQuestion[] =
              (currentProfilePageQuestionQuery.profilePageQuestions?.edges.map(
                (edge) => edge?.node,
              ) as ProfilePageQuestion[]) || [];

            const questionMap =
              currentProfilePageQuestions.reduce<ProfilePageQuestionMap>(
                (obj, question) => {
                  obj[question.id] = question;
                  return obj;
                },
                {},
              );

            writeProfilePageQuestionsQuery({
              cache,
              variables: profilePageQuestionsVar,
              data: {
                ...currentProfilePageQuestionQuery,
                profilePageQuestions: {
                  ...currentProfilePageQuestionQuery.profilePageQuestions,
                  edges: updatedQuestionOrderIds.map((qId) => ({
                    __typename: 'ProfilePageQuestionNodeEdge',
                    node: questionMap[qId],
                  })),
                },
              },
            });
          }
        },
      });
    } catch (e) {
      if (e instanceof Error) {
        Snackbar.show(e.message);
      }
    }
  };

  const onDeleteQuestion = async (questionId: string) => {
    try {
      await destroyProfilePageQuestion({
        variables: {
          input: {
            id: questionId,
          },
        },
        update(cache) {
          const currentProfilePageQuestions = readProfilePageQuestionsQuery({
            cache,
            variables: profilePageQuestionsVar,
          });

          if (currentProfilePageQuestions?.profilePageQuestions?.edges) {
            writeProfilePageQuestionsQuery({
              cache,
              variables: profilePageQuestionsVar,
              data: {
                ...currentProfilePageQuestions,
                profilePageQuestions: {
                  ...currentProfilePageQuestions.profilePageQuestions,
                  edges:
                    currentProfilePageQuestions.profilePageQuestions.edges.filter(
                      (edge) => edge?.node?.id !== questionId,
                    ),
                },
              },
            });
          }
        },
      });
    } catch (e) {
      if (e instanceof Error) {
        Snackbar.show(e.message);
      }
    }
  };

  const onAddPhotoCategory = () => {
    navigation.navigate('ProfilePageAddPhotoCategory', {
      onAddedPhotoCategory: (text) => onAddedPhotoCategory(text),
    });
  };

  const onDeletePhotoCategory = async (photoCategoryId: string) => {
    try {
      await destroyProfilePagePhotoCategory({
        variables: {
          input: {
            id: photoCategoryId,
          },
        },
        update(cache) {
          const currentProfilePagePhotoCategories =
            readProfilePagePhotoCategoriesQuery({
              cache,
              variables: profilePagePhotoCategoriesVar,
            });

          if (
            currentProfilePagePhotoCategories?.profilePagePhotoCategories?.edges
          ) {
            writeProfilePagePhotoCategoriesQuery({
              cache,
              variables: profilePagePhotoCategoriesVar,
              data: {
                ...currentProfilePagePhotoCategories,
                profilePagePhotoCategories: {
                  ...currentProfilePagePhotoCategories.profilePagePhotoCategories,
                  edges:
                    currentProfilePagePhotoCategories.profilePagePhotoCategories.edges.filter(
                      (edge) => edge?.node?.id !== photoCategoryId,
                    ),
                },
              },
            });
          }
        },
      });
    } catch (e) {
      if (e instanceof Error) {
        Snackbar.show(e.message);
      }
    }
  };

  if (!moduleInstance || !profilePageSetupConfig || photoCategoriesLoading) {
    return (
      <ModuleSetupLayoutLoading
        title={t('screens.profilePageSetup')}
        onBack={() => navigation.goBack()}
      />
    );
  }

  return (
    <ProfilePageSetupLayout
      moduleInstance={moduleInstance}
      profilePageSetupConfig={profilePageSetupConfig}
      photoCategories={photoCategories}
      questions={questions}
      onBack={() => navigation.goBack()}
      onAddQuestion={onAddQuestion}
      onUpdateQuestion={onUpdateQuestion}
      onDeleteQuestion={onDeleteQuestion}
      onUpdateQuestionsOrder={onUpdateQuestionsOrder}
      onUpdateModuleInstance={onUpdateModuleInstance}
      onUpdateProfilePageSetup={onUpdateProfilePageSetup}
      onUpdatePhotoCategory={onUpdatePhotoCategory}
      onDeletePhotoCategory={onDeletePhotoCategory}
      onAddPhotoCategory={onAddPhotoCategory}
    />
  );
}
